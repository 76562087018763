import React, { useState, useEffect } from 'react';
import { api } from '../utils/Api';
import profile_icon from '../images/profile_icon.png'
import { useParams } from 'react-router-dom';

const Story = () => {
  const { id } = useParams();
  const [doctor, setDoctor] = useState(null);

  useEffect(() => {
    fetchDoctor();
  }, []);

  const fetchDoctor = async () => {
    try {
      const response = await api.get(`/doctor/${id}`);
      if (response.data.status === 'error') {
        throw new Error(response.data.errors[0].msg);
      }
      setDoctor(response.data.data); // Set the doctor details from response.data.data
    } catch (error) {
      console.error('Failed to fetch doctor data:', error);
    }
  };

  const [stories, setStories] = useState([
    { id: 1, text: 'This is the first story', name: 'samuel moiwa', profile_icon: profile_icon, likes: 0, dislikes: 0 },
    { id: 2, text: 'Here comes another story', name: 'chinedum eke', profile_icon: profile_icon,  likes: 0, dislikes: 0 },
  ]);

  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSubmit = () => {
    if (inputValue.trim()) {
      const newStory = {
        id: stories.length + 1,
        text: inputValue,
        likes: 0,
        dislikes: 0,
      };
      setStories([...stories, newStory]);
      setInputValue('');
    }
  };

  const handleLike = (id) => {
    setStories(stories.map(story =>
      story.id === id ? { ...story, likes: story.likes + 1 } : story
    ));
  };

  const handleDislike = (id) => {
    setStories(stories.map(story =>
      story.id === id ? { ...story, dislikes: story.dislikes + 1 } : story
    ));
  };

  if (!doctor) {
    return <div className='text-gray-500 font-Poppins'>Loading...</div>; // Display a loading message or spinner while fetching data
  }

  return (
    <div className="sm:max-w-2xl w-full mx-auto p-4">
      <h1 className="text-lg font-bold font-Poppins text-gray-600">
        Patient Stories for {doctor.title} {doctor.lastName}
      </h1>
      <p className='text-gray-500 font-Poppins text-xs mb-4'>
        These are patient’s opinions and do not necessarily reflect the doctor’s medical capabilities.
      </p>
      <div className="mb-4">
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          className="border p-2 rounded w-full"
          placeholder="Write a new story..."
        />
        <button
          onClick={handleSubmit}
          className="bg-blue-500 hover:bg-blue-400 text-white p-2 rounded mt-2 w-full"
        >
          Submit
        </button>
      </div>

      <div className=''>
        {stories.map(story => (
          <div key={story.id} className="mb-4">
            <div className='flex items-center mb-2'>
              <img src={story.profile_icon} alt="Profile Icon" className="w-8 h-8 rounded-full" />
              <p className="ml-2 font-bold text-gray-500 text-sm font-Poppins">{story.name}</p>
            </div>
            <div className='border p-4 rounded'>
              <p className='text-gray-600 text-sm font-Poppins'>{story.text}</p>
              <div className="flex mt-2">
                <div className='flex justify-center items-center text-gray-400'>
                  <button
                    onClick={() => handleLike(story.id)}
                    className=" p-2 rounded"
                    aria-label="Like"
                  >
                    <svg class="flex-shrink-0 w-5 h-5 text-gray-400 transition duration-75  group-hover:text-white"
                      aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                      width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                      <path fill-rule="evenodd" d="M15.03 9.684h3.965c.322 0 .64.08.925.232.286.153.532.374.717.645a2.109 2.109 0 0 1 .242 1.883l-2.36 7.201c-.288.814-.48 1.355-1.884 1.355-2.072 0-4.276-.677-6.157-1.256-.472-.145-.924-.284-1.348-.404h-.115V9.478a25.485 25.485 0 0 0 4.238-5.514 1.8 1.8 0 0 1 .901-.83 1.74 1.74 0 0 1 1.21-.048c.396.13.736.397.96.757.225.36.32.788.269 1.211l-1.562 4.63ZM4.177 10H7v8a2 2 0 1 1-4 0v-6.823C3 10.527 3.527 10 4.176 10Z" clip-rule="evenodd"/>
                    </svg>
                  </button>
                  {story.likes}
                </div>
                <div className='flex justify-center items-center text-gray-400 ml-5'>
                  <button
                    onClick={() => handleDislike(story.id)}
                    className=" p-2 rounded"
                    aria-label="Like"
                  >
                    <svg class="flex-shrink-0 w-5 h-5 text-gray-400 transition duration-75  group-hover:text-white"
                      aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                      width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                      <path fill-rule="evenodd" d="M8.97 14.316H5.004c-.322 0-.64-.08-.925-.232a2.022 2.022 0 0 1-.717-.645 2.108 2.108 0 0 1-.242-1.883l2.36-7.201C5.769 3.54 5.96 3 7.365 3c2.072 0 4.276.678 6.156 1.256.473.145.925.284 1.35.404h.114v9.862a25.485 25.485 0 0 0-4.238 5.514c-.197.376-.516.67-.901.83a1.74 1.74 0 0 1-1.21.048 1.79 1.79 0 0 1-.96-.757 1.867 1.867 0 0 1-.269-1.211l1.562-4.63ZM19.822 14H17V6a2 2 0 1 1 4 0v6.823c0 .65-.527 1.177-1.177 1.177Z" clip-rule="evenodd"/>
                    </svg>
                  </button>
                  {story.dislikes}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Story;
