import React, {useState, useEffect} from 'react'
import UserNavBar from '../../components/UserNavBar'
import Lottie from 'lottie-react';
import welcome from'../../lottiefiles/welcome.json'
import Articles from '../../components/Articles';
import AvailableSpecialty from '../../components/userDashboard/AvailableSpecialty';
import { api } from '../../utils/Api';
import OurTopDoctors from '../../components/OurTopDoctors';

const UserDashBoard = () => {

  const [appointments, setAppointments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchAppointments = async () => {
      try {
        setIsLoading(true);
        const response = await api.get('/doctors/appointments?page=1&limit=20');
        const appointmentsData = response.data.data;

        const today = new Date().toLocaleDateString();
        const approvedAppointmentsToday = appointmentsData.filter(appointment => {
          const appointmentDate = new Date(appointment.appointmentDate).toLocaleDateString();
          return appointment.appointmentStatus === 'approved' && appointmentDate === today;
        });

        setAppointments(approvedAppointmentsToday);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching appointments:", error);
        setIsLoading(false);
      }
    };

    fetchAppointments();
    const intervalId = setInterval(fetchAppointments, 5000);
    return () => clearInterval(intervalId);
  }, []);

  const renderAppointments = () => {
    if (isLoading) {
      return <p>Loading...</p>;
    } else if (appointments.length === 0) {
      return <p>No appointments for today.</p>;
    } else {
      const doctorNames = appointments.map(appointment => {
        const doctorName = `${appointment.doctor.firstName} ${appointment.doctor.lastName}`;
        return doctorName;
      });
      const doctorNamesString = doctorNames.join(', ');
      return <p>You have appointments with {doctorNamesString} today.</p>;
    }
  };

  return (
    <div>
      <UserNavBar />

      <div className='lg:pl-72 flex justify-center items-center flex-row w-full lg:px-0 px-4 bg-blue-100 pt-16'>
        {/* add loading state here */}
        <div className='lg:w-1/2 flex sm:mt-0 lg:mt-0 mt-16 sm:flex-row-reverse flex-col-reverse justify-center items-center max-w-7xl'>
          <div className='sm:w-1/2 flex justify-center items-center'>
            <Lottie className='w-full' animationData={welcome} loop={true} />
          </div>
          <div className='flex flex-col text-gray-600 items-center sm:w-full px-4 sm:px-0'>
              <h1 className='text-xl text-center font-bold font-Poppins'>Welcome to Kenecare dashboard</h1>
              <p className='text-center font-Poppins'>With Kenecare, you can meet your doctor at the comfort of your home. Make an appointment with your doctor today.</p>
              {renderAppointments()}
            </div>
        </div>
      </div>

      <div className=' bg-white lg:pl-72 flex justify-center items-center lg:px-0 px-4 sm:flex-row flex-col w-full py-16 border-b'>
        <OurTopDoctors/>
      </div>

      <div className=' bg-white sm:mt-10 mt-5 lg:pl-72 flex justify-center items-center lg:px-0 px-4 sm:flex-row flex-col w-full py-16 border-b'>
        <AvailableSpecialty/>
      </div>

      <div className=' bg-white sm:mt-16 mt-5 lg:pl-72 flex justify-center items-center lg:px-0 px-4 sm:flex-row flex-col w-full py-16'>
        <Articles/>
      </div>
    </div>
  )
}

export default UserDashBoard
