import React, { useState, useEffect } from 'react';
import { api } from '../../utils/Api';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    selectedDoctor: Yup.string().required('Please select a doctor'),
    note: Yup.string().required('Please enter a note'),
    password: Yup.string().required('Please enter your password'),
});

function ShareDocumentPopup({ documentId, onClose }) {
    const [doctors, setDoctors] = useState([]);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        const fetchDoctors = async () => {
            try {
                const response = await api.get('/doctors');
                if (response.data.status === "success") {
                    setDoctors(response.data.data);
                } else {
                    setDoctors([]);
                }
            } catch (error) {
                console.error("Error fetching doctors:", error);
                setDoctors([]);
            }
        };

        fetchDoctors();
    }, []);


    useEffect(() => {
        let messageTimeout;
        if (successMessage) {
          messageTimeout = setTimeout(() => {
            setSuccessMessage('');
          }, 4000);
        }

        if (errorMessage) {
          messageTimeout = setTimeout(() => {
            setErrorMessage('');
          }, 4000);
        }

        return () => clearTimeout(messageTimeout);
      }, [successMessage, errorMessage]);



    const handleShare = async (values, actions) => {
        try {
            const response = await api.post('/patients/shared-docs', {
                documentId: documentId,
                doctorId: values.selectedDoctor,
                note: values.note,
                password: values.password,  // include password in the request
            }).catch(error => {
                throw error;
            });
            if (response.data && response.data.status === "success") {
                setSuccessMessage('Document shared successfully.'); // Display success message
                onClose();
            } else {
                throw new Error('Sharing document was not successful.');
            }
        } catch ({response}) {
            console.error('Error sharing document', response);
            if(response.status === 304) {
                setErrorMessage('This document has already been shared with the selected doctor.');
            } else {
                setErrorMessage(response || 'An error occurred while sharing the document.');
            }
        }
    };

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
      };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 lg:pl-72">
            <div className="bg-white p-6 rounded-lg">
                <h2 className="text-lg font-semibold mb-4">Share Document</h2>

                <Formik
                    initialValues={{ selectedDoctor: '', note: '', password: '' }}
                    validationSchema={validationSchema}
                    onSubmit={handleShare}
                >
                    {({ isSubmitting }) => (
                        <Form>

                            {successMessage && (
                            <div className="bg-green-200 col-span-2 p-2 text-green-700 mb-4 rounded text-xs">
                                {successMessage}
                            </div>
                            )}

                            {errorMessage && (
                                <div className="bg-red-200 col-span-2 p-2 text-red-700 mb-4 rounded text-xs">
                                    {errorMessage.message || errorMessage.toString()}
                                </div>
                            )}

                            <div className="mb-4">
                                <label htmlFor="selectedDoctor">Select Doctor</label>
                                <Field as="select" name="selectedDoctor" className="appearance-none border
                                    border-blue-300 rounded w-full py-2 px-3 text-gray-700 leading-tight
                                    focus:outline-none focus:shadow-outline">
                                    <option value="">Select Doctor</option>
                                    {doctors.map(doctor => (
                                        <option key={doctor.doctorId} value={doctor.doctorId}>{doctor.title} {doctor.firstName} {doctor.middleName} {doctor.lastName} ID:{doctor.doctorId}
                                        </option>
                                    ))}
                                </Field>
                                <ErrorMessage name="selectedDoctor" component="div" className="text-red-500" />
                            </div>

                            <div className="mb-4">
                                <label htmlFor="password">Password</label>
                                <div className="relative w-full">
                  <Field
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    className="appearance-none border border-blue-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Enter your password"
                  />

                  <button
                    type="button"
                    onClick={toggleShowPassword}
                    className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm"
                  >
                    {showPassword ? (
                      <svg class="w-6 h-6 text-blue-300"
                        aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                        width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                        <path d="m4 15.6 3.055-3.056A4.913 4.913 0 0 1 7 12.012a5.006 5.006 0 0 1 5-5c.178.009.356.027.532.054l1.744-1.744A8.973 8.973 0 0 0 12 5.012c-5.388 0-10 5.336-10 7A6.49 6.49 0 0 0 4 15.6Z"/>
                        <path d="m14.7 10.726 4.995-5.007A.998.998 0 0 0 18.99 4a1 1 0 0 0-.71.305l-4.995 5.007a2.98 2.98 0 0 0-.588-.21l-.035-.01a2.981 2.981 0 0 0-3.584 3.583c0 .012.008.022.01.033.05.204.12.402.211.59l-4.995 4.983a1 1 0 1 0 1.414 1.414l4.995-4.983c.189.091.386.162.59.211.011 0 .021.007.033.01a2.982 2.982 0 0 0 3.584-3.584c0-.012-.008-.023-.011-.035a3.05 3.05 0 0 0-.21-.588Z"/>
                        <path d="m19.821 8.605-2.857 2.857a4.952 4.952 0 0 1-5.514 5.514l-1.785 1.785c.767.166 1.55.25 2.335.251 6.453 0 10-5.258 10-7 0-1.166-1.637-2.874-2.179-3.407Z"/>
                      </svg>
                    ) : (
                      <svg class="w-6 h-6 text-blue-300"
                        aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                        width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                        <path fill-rule="evenodd" d="M4.998 7.78C6.729 6.345 9.198 5 12 5c2.802 0 5.27 1.345 7.002 2.78a12.713 12.713 0 0 1 2.096 2.183c.253.344.465.682.618.997.14.286.284.658.284 1.04s-.145.754-.284 1.04a6.6 6.6 0 0 1-.618.997 12.712 12.712 0 0 1-2.096 2.183C17.271 17.655 14.802 19 12 19c-2.802 0-5.27-1.345-7.002-2.78a12.712 12.712 0 0 1-2.096-2.183 6.6 6.6 0 0 1-.618-.997C2.144 12.754 2 12.382 2 12s.145-.754.284-1.04c.153-.315.365-.653.618-.997A12.714 12.714 0 0 1 4.998 7.78ZM12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" clip-rule="evenodd"/>
                      </svg>
                    )}
                  </button>
                </div>
                                <ErrorMessage name="password" component="div" className="text-red-500" />
                            </div>

                            <div className="mb-4">
                                <label htmlFor="note">Note</label>
                                <Field as="textarea" name="note" placeholder="write short note for the doctor to understand reason for sharing"
                                className="appearance-none border border-blue-300
                                    rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none
                                    focus:shadow-outline"
                                />
                                <ErrorMessage name="note" component="div" className="text-red-500" />
                            </div>

                            <div className="flex justify-end">
                                <button
                                    type="button"
                                    className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none
                                        bg-white rounded border border-blue-300  hover:bg-red-100
                                        hover:text-red-700 focus:z-10 focus:ring-4 focus:ring-gray-200 w-full mt-5
                                        font-Poppins"
                                    onClick={onClose}
                                    disabled={isSubmitting}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none
                                        bg-white rounded border border-blue-300  hover:bg-gray-100
                                        hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 w-full mt-5
                                        font-Poppins"
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? 'Sharing...' : 'Share'}
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
}

export default ShareDocumentPopup;
