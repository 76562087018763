import React from 'react';
import DoctorNavBar from '../../components/DoctorNavBar';
import DoctorProfileDetails from '../../components/doctorDashboard/DoctorDetailProfile';
import GetCouncilRegistration from '../../components/doctorDashboard/GetCouncilRegistration';

const DoctorProfile = () => {
  return (
    <div className='w-full sm:h-screen h-full bg-blue-100 flex justify-center items-center'>
      <DoctorNavBar/>

      <div className='w-full lg:pl-72 flex justify-center items-center lg:px-0 px-4 sm:gap-0 gap-7 mt-[5rem] sm:mt-0'>
        <DoctorProfileDetails/>
      </div>

      {/* <div className='w-full mt-8 lg:ml-72 flex lg:px-0 px-4 sm:flex-row flex-col sm:gap-0 gap-7 border-t pt-6'>
        <GetCouncilRegistration/>
      </div> */}
    </div>
  );
};

export default DoctorProfile;
