/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../images/logo.png';
import DoctorProfile from './doctorDashboard/DoctorProfile';
import { api } from '../utils/Api';

const DoctorNavBar = () => {

   const [pendingAppointmentsCount, setPendingAppointmentsCount] = useState(0);


   let location = useLocation();

   useEffect(() => {
      const fetchAppointments = async () => {
        try {
          const response = await api.get('/doctors/appointments?page=1&limit=20');
          const appointments = response.data.data;
          const pendingCount = appointments.filter(appointment => appointment.appointmentStatus === 'pending').length;
          setPendingAppointmentsCount(pendingCount);
        } catch (error) {
          console.error("Error fetching appointments:", error);
        }
      };

      fetchAppointments();
    }, []); // Dependency array empty means this runs once on mount



   // Step 1: Add a state variable to track sidebar visibility
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  // Step 2: Event handler function to toggle sidebar visibility
  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  const handleLogout = () => {
   localStorage.removeItem('token');
   localStorage.removeItem('type');
   window.location.href = '/';
 };


  // Helper function to add 'active' class to current route link
  const isActive = (path) => {
   return location.pathname === path ? 'bg-blue-500 text-white' : 'text-gray-900';
 }


  return (
    <div>

      <nav class="fixed top-0 z-50 w-full bg-white border-b border-gray-200 shadow-md">
      <div class="px-3 py-3 lg:px-5 lg:pl-3">
         <div class="flex items-center justify-between">
            <div class="flex items-center justify-start rtl:justify-end">

            <button
               onClick={toggleSidebar}
               className="inline-flex items-center p-2 text-sm text-blue-700 rounded-lg lg:hidden hover:bg-gray-100
               focus:outline-none focus:ring-2 focus:ring-blue-700"
               >
               <span className="sr-only">Toggle sidebar</span>
               <svg
                  className="w-6 h-6"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
               >
                  <path
                     clipRule="evenodd"
                     fillRule="evenodd"
                     d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                  ></path>
               </svg>
            </button>
            <a href="/" class="flex ms-2 md:me-24">
               <img src={logo} alt="Logo" class="h-12" />
            </a>
            </div>
         </div>
      </div>
      </nav>


      <aside
        id="logo-sidebar"
        className={`fixed top-0 left-0 z-40 w-64 h-screen pt-20 transition-transform ${
          isSidebarVisible ? 'translate-x-0' : '-translate-x-full'
        } bg-white lg:translate-x-0 `}
        aria-label="Sidebar"
      >

      <DoctorProfile />

         <div class="h-full px-3 pb-4 overflow-y-auto bg-white rounded-t-lg border border-gray-300">
            <ul class="space-y-2 font-medium mt-4">
               <li>
                  <a  href="/doctorDashboard" className={`flex items-center p-2 rounded text-sm hover:text-white hover:bg-blue-500 group ${isActive('/doctorDashboard')}`}>
                  <svg className={`flex-shrink-0 w-5 h-5 transition duration-75 group-hover:text-white ${isActive('/doctorDashboard') === 'bg-blue-500 text-white' ? 'text-white' : 'text-gray-500'}`}
                     aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                     <path fill-rule="evenodd" d="M4.9 3C3.9 3 3 3.8 3 4.9V9c0 1 .8 1.9 1.9 1.9H9c1 0 1.9-.8 1.9-1.9V5c0-1-.8-1.9-1.9-1.9H5Zm10 0c-1 0-1.9.8-1.9 1.9V9c0 1 .8 1.9 1.9 1.9H19c1 0 1.9-.8 1.9-1.9V5c0-1-.8-1.9-1.9-1.9h-4Zm-10 10c-1 0-1.9.8-1.9 1.9V19c0 1 .8 1.9 1.9 1.9H9c1 0 1.9-.8 1.9-1.9v-4c0-1-.8-1.9-1.9-1.9H5Zm10 0c-1 0-1.9.8-1.9 1.9V19c0 1 .8 1.9 1.9 1.9H19c1 0 1.9-.8 1.9-1.9v-4c0-1-.8-1.9-1.9-1.9h-4Z" clip-rule="evenodd"/>
                  </svg>
                     <span class="ms-3">Dashboard</span>
                  </a>
               </li>
               <li>
                  <a  href="/doctorProfile" className={`flex items-center p-2 rounded text-sm hover:text-white hover:bg-blue-500 group ${isActive('/doctorProfile')}`}>
                  <svg className={`flex-shrink-0 w-5 h-5 transition duration-75 group-hover:text-white ${isActive('/doctorProfile') === 'bg-blue-500 text-white' ? 'text-white' : 'text-gray-500'}`}
                     aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                     <path fill-rule="evenodd" d="M12 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm-2 9a4 4 0 0 0-4 4v1c0 1.1.9 2 2 2h8a2 2 0 0 0 2-2v-1a4 4 0 0 0-4-4h-4Z" clip-rule="evenodd"/>
                  </svg>
                     <span class="flex-1 ms-3 whitespace-nowrap">Profile</span>
                  </a>
               </li>
               <li>
                  <a  href="/appointments_doctor" className={`flex items-center p-2 rounded text-sm hover:text-white hover:bg-blue-500 group ${isActive('/appointments_doctor')}`}>
                  <svg className={`flex-shrink-0 w-5 h-5 transition duration-75 group-hover:text-white ${isActive('/appointments_doctor') === 'bg-blue-500 text-white' ? 'text-white' : 'text-gray-500'}`}
                     aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                     <path fill-rule="evenodd" d="M14 7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v10c0 1.1.9 2 2 2h8a2 2 0 0 0 2-2V7Zm2 9.4 4.7 1.5A1 1 0 0 0 22 17V7a1 1 0 0 0-1.3-1L16 7.7v8.8Z" clip-rule="evenodd"/>
                  </svg>
                     <span class="flex-1 ms-3 whitespace-nowrap">Appointments</span>
                     <span class="inline-flex items-center justify-center px-2 ms-3 text-sm font-medium text-white bg-orange-500 rounded-full">{pendingAppointmentsCount}</span>
                  </a>
               </li>
               <li>
                  <a  href="/comingSoon" className={`flex items-center p-2 rounded text-sm hover:text-white hover:bg-blue-500 group ${isActive('/comingSoon')}`}>
                  <svg className={`flex-shrink-0 w-5 h-5 transition duration-75 group-hover:text-white ${isActive('/comingSoon') === 'bg-blue-500 text-white' ? 'text-white' : 'text-gray-500'}`}
                     aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                     <path d="M17 6h-2V5h1a1 1 0 1 0 0-2h-2a1 1 0 0 0-1 1v2h-.5a6 6 0 0 1 1.5 4v4a1 1 0 1 1-2 0v-4a4 4 0 0 0-4-4h-.5C5 6 3 8 3 10.5V16c0 .6.4 1 1 1h7v3c0 .6.4 1 1 1h2c.6 0 1-.4 1-1v-3h5c.6 0 1-.4 1-1v-6a4 4 0 0 0-4-4Zm-9 8.5H7a1 1 0 1 1 0-2h1a1 1 0 1 1 0 2Z"/>
                  </svg>
                     <span class="flex-1 ms-3 whitespace-nowrap">Chats</span>
                     <span class="inline-flex items-center justify-center w-3 h-3 p-3 ms-3 text-sm font-medium text-white bg-orange-500 rounded-full ">0</span>
                  </a>
               </li>
               <li>
                  <a  href="/patentMedicalDocuments" className={`flex items-center p-2 rounded text-sm hover:text-white hover:bg-blue-500 group ${isActive('/patentMedicalDocuments')}`}>
                  <svg className={`flex-shrink-0 w-5 h-5 transition duration-75 group-hover:text-white ${isActive('/patentMedicalDocuments') === 'bg-blue-500 text-white' ? 'text-white' : 'text-gray-500'}`}
                     aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                     <path fill-rule="evenodd" d="M9 2.2V7H4.2l.4-.5 3.9-4 .5-.3Zm2-.2v5a2 2 0 0 1-2 2H4v11c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2h-7ZM8 16c0-.6.4-1 1-1h6a1 1 0 1 1 0 2H9a1 1 0 0 1-1-1Zm1-5a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2H9Z" clip-rule="evenodd"/>
                     </svg>
                     <span class="flex-1 ms-3 whitespace-nowrap">Patient Medical DOC</span>
                  </a>
               </li>
               <li>
                  <a  href="/wallet" className={`flex items-center p-2 rounded text-sm hover:text-white hover:bg-blue-500 group ${isActive('/wallet')}`}>
                  <svg className={`flex-shrink-0 w-5 h-5 transition duration-75 group-hover:text-white ${isActive('/wallet') === 'bg-blue-500 text-white' ? 'text-white' : 'text-gray-500'}`}
                        aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" stroke-linecap="round" stroke-width="2" d="M3 21h18M4 18h16M6 10v8m4-8v8m4-8v8m4-8v8M4 9.5v-1c0-.3.2-.6.5-.8l7-4.5a1 1 0 0 1 1 0l7 4.5c.3.2.5.5.5.8v1c0 .3-.2.5-.5.5h-15a.5.5 0 0 1-.5-.5Z"/>
                     </svg>
                     <span class="flex-1 ms-3 whitespace-nowrap">Wallet</span>
                  </a>
               </li>
               <li>
                  <a  href="/doctorSettingsPage" className={`flex items-center p-2 rounded text-sm hover:text-white hover:bg-blue-500 group ${isActive('/doctorSettingsPage')}`}>
                  <svg className={`flex-shrink-0 w-5 h-5 transition duration-75 group-hover:text-white ${isActive('/doctorSettingsPage') === 'bg-blue-500 text-white' ? 'text-white' : 'text-gray-500'}`}
                  aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                     <path fill-rule="evenodd" d="M17 10v1.1l1 .5.8-.8 1.4 1.4-.8.8.5 1H21v2h-1.1l-.5 1 .8.8-1.4 1.4-.8-.8a4 4 0 0 1-1 .5V20h-2v-1.1a4 4 0 0 1-1-.5l-.8.8-1.4-1.4.8-.8a4 4 0 0 1-.5-1H11v-2h1.1l.5-1-.8-.8 1.4-1.4.8.8a4 4 0 0 1 1-.5V10h2Zm.4 3.6c.4.4.6.8.6 1.4a2 2 0 0 1-3.4 1.4A2 2 0 0 1 16 13c.5 0 1 .2 1.4.6ZM5 8a4 4 0 1 1 8 .7 7 7 0 0 0-3.3 3.2A4 4 0 0 1 5 8Zm4.3 5H7a4 4 0 0 0-4 4v1c0 1.1.9 2 2 2h6.1a7 7 0 0 1-1.8-7Z" clip-rule="evenodd"/>
                  </svg>
                     <span class="flex-1 ms-3 whitespace-nowrap">Settings</span>
                  </a>
               </li>
               <li>
                  <button onClick={handleLogout}
                  class="flex items-center p-2 text-gray-900 rounded-lg text-sm hover:text-white
                  hover:bg-blue-500 group hover:cursor-pointer w-full">
                  <svg class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75  group-hover:text-white"
                     aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                     <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 12H4m12 0-4 4m4-4-4-4m3-4h2a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3h-2"/>
                  </svg>
                     <span class="ml-3">Sign Out</span>
                  </button>
               </li>
            </ul>

            <div className='absolute inset-x-0 bottom-0 mb-5'>
            <p className='text-gray-400 text-center text-xxs mt-2'>
            &copy; 2023 All Rights Reserved. powered by <br /> <Link
            to='https://sl.imo-tech.com/'
            target='_blank'
            >
            <span className='hover:text-blue-500 cursor-pointer font-bold'>IMO-Technologies</span>
            </Link>
            </p>
            </div>
         </div>

      </aside>


    </div>
  )
}

export default DoctorNavBar

