import React, { useEffect } from "react";
import { connect } from "react-redux";
import { fetchDoctorProfile } from "../../redux/actions";
import { Link } from 'react-router-dom';
import { api } from "../../utils/Api";

const DoctorDetailProfile = ({ userProfile, fetchUserProfile }) => {
    useEffect(() => {
        fetchUserProfile();
    }, [fetchUserProfile]); // Correct dependency

    const profileImageUrl = userProfile.data.profilePic
      ? `${userProfile.data.profilePic}`
      : 'default_image_url';
  return (
    <div className="w-full">
    {/* <h1 className="text-2xl font-bold mb-4">User Profile</h1> */}
    {userProfile.loading ? (
       <div className="flex justify-center items-center h-screen">
       <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-300
       h-32 w-32 flex justify-center items-center"
       >
         <p className="text-gray-500 ml-4 text-sm">Loading...</p>
       </div>
      </div>
    ) : userProfile.error ? (
      <p>Error: {userProfile.error}</p>
    ) : (
      <div className="w-full sm:h-screen h-full flex justify-center items-center py-6">
        <div
          className="lg:w-1/2 w-full flex flex-col justify-center items-center py-10 rounded shadow-lg"
          style={{ backgroundImage: 'linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%)' }}
        >

          <img
            src={profileImageUrl}
            alt="Profile Pic"
            className=" h-60 w-60 rounded-full bg-white p-1 start-0"
          />

          <div className="flex sm:flex-row flex-col w-full mt-7">
            <div className=" p-4 flex flex-col sm:w-1/2 w-full">
              <p className="text-lg font-semibold mt-2 text-gray-700">
              {`${userProfile.data.title} ${userProfile.data.firstName} ${userProfile.data.middleName} ${userProfile.data.lastName}`}
              </p>
                <p className="font-semibold text-sm text-gray-700">Doctor ID: {userProfile.data.doctorId}</p>
                <p className="text-sm text-gray-500">Gender: {userProfile.data.gender}</p>

                <p className="text-sm text-gray-500">Specialization: {userProfile.data.specialization}</p>
                <p className=" text-sm text-gray-500">Qualifications: {userProfile.data.qualifications}</p>
                <p className="text-sm text-gray-500">Years of Experience: {userProfile.data.yearOfExperience}</p>
                <p className="text-sm text-gray-500">Consultation Fees: {userProfile.data.consultationFees}</p>

                <div className=" flex flex-col gap-3 mt-2">
                  <div className="flex gap-1 items-center">
                  <svg
                    class="flex-shrink-0 w-4 h-4 text-orange-500 transition duration-75  group-hover:text-orange-400"
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                    <path fill-rule="evenodd" d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z" clip-rule="evenodd" />
                  </svg>
                    <p className="text-xs text-blue-500">{userProfile.data.mobileNumber}</p>
                  </div>

                  <div className="flex gap-1 items-center">
                  <svg
                    class="flex-shrink-0 w-4 h-4 text-orange-500 transition duration-75  group-hover:text-orange-400"
                    aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M2 5.6V18c0 1.1.9 2 2 2h16a2 2 0 0 0 2-2V5.6l-.9.7-7.9 6a2 2 0 0 1-2.4 0l-8-6-.8-.7Z"/>
                    <path d="M20.7 4.1A2 2 0 0 0 20 4H4a2 2 0 0 0-.6.1l.7.6 7.9 6 7.9-6 .8-.6Z"/>
                  </svg>
                    <p className="text-xs text-blue-500">{userProfile.data.email}</p>
                  </div>

                  <div className="flex gap-1 items-center">
                  <svg
                    class="flex-shrink-0 w-4 h-4 text-orange-500 transition duration-75  group-hover:text-orange-400"
                    aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                    <path fill-rule="evenodd" d="M12 2a8 8 0 0 1 6.6 12.6l-.1.1-.6.7-5.1 6.2a1 1 0 0 1-1.6 0L6 15.3l-.3-.4-.2-.2v-.2A8 8 0 0 1 11.8 2Zm3 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" clip-rule="evenodd"/>
                  </svg>
                    <p className="text-xs text-blue-500">{userProfile.data.city}</p>
                  </div>

                </div>
            </div>

            <div className="sm:w-1/2 w-full max-w-profile-summary sm:ml-3">
              <p className="text-sm font-semibold text-gray-700 px-2">Professional Summary</p>
              <p className="text-justify px-3 mt-2 text-xs text-gray-500">{userProfile.data.professionalSummary}</p>
            </div>
          </div>

          <div className="flex flex-col w-full justify-center items-center border-t border-gray-100 mt-10">
              <Link to="/doctorSettingsPage" className="text-blue-800 font-semibold text-sm mt-5">
              <svg class="w-6 h-6 text-blue-900 transition duration-75 hover:text-gray-600 mt-5"
                aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                <path fill-rule="evenodd" d="M5 8a4 4 0 1 1 7.796 1.263l-2.533 2.534A4 4 0 0 1 5 8Zm4.06 5H7a4 4 0 0 0-4 4v1a2 2 0 0 0 2 2h2.172a2.999 2.999 0 0 1-.114-1.588l.674-3.372a3 3 0 0 1 .82-1.533L9.06 13Zm9.032-5a2.907 2.907 0 0 0-2.056.852L9.967 14.92a1 1 0 0 0-.273.51l-.675 3.373a1 1 0 0 0 1.177 1.177l3.372-.675a1 1 0 0 0 .511-.273l6.07-6.07a2.91 2.91 0 0 0-.944-4.742A2.907 2.907 0 0 0 18.092 8Z" clip-rule="evenodd"/>
              </svg>
              </Link>
            </div>
        </div>
      </div>
    )}

  </div>
  )
}

const mapStateToProps = (state) => ({
    userProfile: state.doctorProfile, // Ensure this path matches your actual Redux state structure
});

const mapDispatchToProps = (dispatch) => ({
    fetchUserProfile: () => dispatch(fetchDoctorProfile()),
});

export default connect(mapStateToProps, mapDispatchToProps) (DoctorDetailProfile);
