import React, { useEffect, useState } from 'react';
import { api } from '../../utils/Api';
import { Formik, FieldArray, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Tooltip } from "flowbite-react";

const Appointments = () => {
  const [appointments, setAppointments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const pageLimit = 5;

  const [isActionModalVisible, setIsActionModalVisible] = useState(false);
  const [selectedAppointmentId, setSelectedAppointmentId] = useState(null);
  const [actionFeedback, setActionFeedback] = useState('');

  const [isPostponeFormVisible, setIsPostponeFormVisible] = useState(false);

  const handleAction = (id) => {
    setSelectedAppointmentId(id);
    setActionFeedback('');
    setIsActionModalVisible(true);
  };

  const handleApprove = async () => {
    if (selectedAppointmentId) {
      try {
        await api.patch(`/doctors/appointments/${selectedAppointmentId}/approve`).catch((error) => {
          throw error;
        });
        setActionFeedback('Appointment approved successfully.');
        setTimeout(() => setActionFeedback(''), 3000);
        setIsActionModalVisible(false);
      } catch (error) {
        if (error.response.status === 304) {
          setActionFeedback('This appointment has already been approved.');
        } else {
        setActionFeedback('An error occurred while approving the appointment.');
        }
        setTimeout(() => setActionFeedback(''), 3000);
      }
    }
  };

  const handlePostponeSubmit = async (values) => {
    if (selectedAppointmentId) {
      try {
        const response = await api.patch(`/doctors/appointments/${selectedAppointmentId}/postpone`, {
          reason: values.reason,
          newDate: values.newDate,
        });
        if (response.status === 304) {
          setActionFeedback('This appointment has already been approved.');
        } else  if(response.status.data) {
          setActionFeedback('This appointment has already been postponed.');
        }
        else {
          setActionFeedback('Appointment approved successfully.');
        }
        setActionFeedback('Appointment postponed successfully.');
        setTimeout(() => setActionFeedback(''), 3000);
      } catch (error) {
        const message = error.response.data;
        setActionFeedback(message);
        setTimeout(() => setActionFeedback(''), 3000);
      } finally {
        setIsPostponeFormVisible(false);
        // Optionally, refresh the appointments list here
      }
    }
  };

  const PostponeForm = ({ isOpen, onClose, onSubmit, initialDate, feedback }) => {

    if (!isOpen) return null;

    const validationSchema = Yup.object().shape({
      reason: Yup.string().required('A reason is required'),
      newDate: Yup.date().min(new Date(), 'The date must be in the future').required('A new date is required'),
    });

    return (
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
        <div className="relative p-5 sm:ml-72 border w-96 shadow-lg rounded-md bg-white">
          <Formik
            initialValues={{ reason: '', newDate: initialDate }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ setFieldValue }) => (
              <Form>
                <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">Postpone Appointment</h3>
                {feedback && <p className="text-sm text-red-500 bg-red-200 px-1 py-2 rounded">{feedback}</p>}
                <div className="mb-4">
                  <label htmlFor="reason" className="block text-sm font-medium text-gray-700">Reason</label>
                  <Field name="reason" as="textarea" className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"/>
                  <ErrorMessage name="reason" component="div" className="text-red-500 text-sm"/>
                </div>

                <div className="mb-4">
                  <label htmlFor="newDate" className="block text-sm font-medium text-gray-700">New Date</label>
                  <Field name="newDate" type="date" className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"/>
                  <ErrorMessage name="newDate" component="div" className="text-red-500 text-sm"/>
                </div>

                <div className="flex items-center justify-between gap-4">
                  <button type="submit" className="inline-flex justify-center rounded border border-transparent px-4
                    py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none
                    focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Submit</button>
                  <button onClick={onClose} type="button" className="inline-flex justify-center rounded border
                  border-gray-300 px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50
                  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Cancel</button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  };

  //============================ APPOINTMENT DETAILS COMPONENT ========================

  const AppointmentDetails = ({ onClose}) => {
    if (!selectedAppointmentId) return null;
  // Find the selected appointment from the appointments array
  const selectedAppointment = appointments.find(appointment => appointment.appointmentId === selectedAppointmentId);
  if (!selectedAppointment) return null;
    return (
      <>
        {actionFeedback && <p className="text-sm text-red-500 bg-red-200 px-1 py-2 rounded">{actionFeedback}</p>}
        <div>
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">Appointment Details</h3>
            <div className="mt-2">
              <p className="text-sm text-gray-500">View the details of the appointment below.</p>
            </div>
              <div className="w-full text-start my-4 sm:px-8 bg-gray-50 py-3">
              <p className='text-sm font-Poppins'><strong>ID:</strong> {selectedAppointment.appointmentId}</p>
              <p className='text-sm font-Poppins'><strong>Username:</strong> {selectedAppointment.username}</p>
              <p className='text-sm font-Poppins'><strong>Patient Name:</strong> {selectedAppointment.patientNameOnPrescription}</p>
              <p className='text-sm font-Poppins'><strong className='text-red-800'>Symptoms:</strong> {selectedAppointment.patientSymptoms}</p>
              <p className='text-sm font-Poppins'><strong>Mobile Number:</strong> {selectedAppointment.patientMobileNumber}</p>
              <p className='text-sm font-Poppins'><strong>Payment Method:</strong> {selectedAppointment.paymentMethod}</p>
              <p className='text-sm font-Poppins'><strong>Appointment Date:</strong> {new Date(selectedAppointment.appointmentDate).toLocaleDateString()}</p>
              <p className='text-sm font-Poppins'><strong>Time:</strong> {selectedAppointment.appointmentTime}</p>
              <p className='text-sm font-Poppins'><strong>Type:</strong> {selectedAppointment.appointmentType}</p>
              <p className='text-sm font-Poppins'><strong>Status:</strong> {selectedAppointment.appointmentStatus}</p>
              </div>
          </div>

          <h3 className="text-lg leading-6 font-medium text-gray-900">Appointment Action</h3>
          <div className="mt-2">
            <p className="text-sm text-gray-500">Do you want to approve or postpone this appointment?</p>
          </div>
          <div className="flex flex-row items-center gap-4 mt-4">
            <button onClick={handleApprove} onClose={onClose} className="inline-flex justify-center w-full rounded border border-transparent
            px-1 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none
            focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm">

            <svg class="w-5 h-5 text-white" aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
              <path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z" clip-rule="evenodd"/>
            </svg>
              Approve
            </button>
            <button onClick={() => { setIsActionModalVisible(false); setIsPostponeFormVisible(true); }}
            className="inline-flex justify-center w-full rounded border border-transparent px-1 py-2 bg-yellow-400
            text-base font-medium text-white hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2
            focus:ring-yellow-500 sm:text-sm">

              <svg class="w-5 h-5 text-white" aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m7 16 4-4-4-4m6 8 4-4-4-4"/>
              </svg>
              Postpone
            </button>
            </div>

          <div className='w-full mt-4 mb-6 lg:px-10 px-3 flex justify-center items-center sm:overflow-x-hidden overflow-x-scroll' role='group'>
            <div className="inline-flex rounded-md shadow-sm w-full" role="group">
              <button
                type="button"
                className={`px-4 py-2 text-xs font-medium  hover:text-blue-700 hover:bg-gray-100
              text-gray-900 bg-white' border border-gray-200 rounded-s-lg focus:z-10 focus:ring-2
                focus:ring-blue-700`}
              >
                Request Medical Document
              </button>
              <button
                type="button"
                className={`px-4 py-2 text-xs font-medium hover:text-blue-700 hover:bg-gray-100
                text-gray-900 bg-white border border-gray-200 rounded-e-lg focus:z-10 focus:ring-2
                focus:ring-blue-700`}
              >
                Refer to another doctor
              </button>
            </div>
        </div>
        </div>

      </>
    );
  };

//======================= PRESCRIPTIONS COMPONENT ========================
const Prescriptions = () => {
  const [prescriptions, setPrescriptions] = useState([]);
  const [formError, setFormError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);

  // Function to add a new prescription
  const addPrescription = (arrayHelpers) => {
    arrayHelpers.push({
      medicineName: '',
      strength: '',
      dosage: '',
      treatmentDays: ''
    });
  };

  // Function to remove a prescription at a given index
  const removePrescription = (arrayHelpers, index) => {
    arrayHelpers.remove(index);
  };

  if (!selectedAppointmentId) return null;
  // Find the selected appointment from the appointments array
  appointments.find(appointment => appointment.appointmentId === selectedAppointmentId);

  const validationSchema = Yup.object().shape({
    diagnosis: Yup.string().required("Diagnosis is required"),
    comment: Yup.string(),
    prescriptions: Yup.array().of(
      Yup.object({
        medicineName: Yup.string().required("Medicine Name is required"),
        strength: Yup.string().required("Strength is required"),
        dosage: Yup.string().required("Dosage is required"),
        treatmentDays: Yup.number()
          .typeError("Treatment days must be a number")
          .required("Treatment days is required")
          .min(1, "Treatment days must be at least 1")
      })
    )
  });

  const handleSubmit = async (values, { resetForm }) => {
    setLoading(true); // Set loading state to true when form is being submitted
    try {
      const response = await api.post('/doctors/prescriptions', {
        appointmentId: selectedAppointmentId,
        diagnosis: values.diagnosis,
        medicines: values.prescriptions.map(prescription => ({
          medicineName: prescription.medicineName,
          strength: prescription.strength,
          dosage: prescription.dosage,
          treatmentDuration: prescription.treatmentDays + " days",
          comment: prescription.comment
        })),
        comment: values.comment
      });

      const newPrescriptions = response.data.medicines;
      if (Array.isArray(newPrescriptions)) {
        setPrescriptions([...prescriptions, ...newPrescriptions]);
      } else {
        setPrescriptions([...prescriptions, newPrescriptions]);
      }
      resetForm();
      setSuccessMessage('Form submitted successfully');
      setTimeout(() => {
        setSuccessMessage('');
      }, 3000);
    } catch (error) {
      console.error('Error submitting form:', error);

      if (error.response && error.response.data && error.response.data.message) {
        // If there is an error response from the backend
        const errorMessage = error.response.data.message;
        setFormError(errorMessage);
      } else {
        // For other types of errors
        setFormError('An error occurred while submitting the form.');
      }
    } finally {
      setLoading(false); // Set loading state back to false when submission is complete
    }
  };

  return (
    <div className="flex p-4 space-x-4">
      <div className="w-full">
        <Formik
          initialValues={{
            appointmentId: selectedAppointmentId,
            diagnosis: '',
            comment: '',
            prescriptions: [{
              medicineName: '',
              strength: '',
              dosage: '',
              treatmentDays: ''
            }],
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched }) => (
            <Form className="w-full">
              <h1 className="py-3 text-sm text-gray-400">Add prescriptions </h1>

              <div className="mb-4">
                <Field as="textarea" name="diagnosis" className="p-2 border border-gray-300 rounded mb-2 w-full" placeholder="Diagnosis" type='text' />
                <ErrorMessage name="diagnosis" component="div" className="text-red-500 text-xs" />
              </div>

              <FieldArray
                name="prescriptions"
                render={arrayHelpers => (
                  <div className='sm:max-h-[22rem] overflow-y-auto'>
                    {values.prescriptions.map((prescription, index) => (
                      <>

                        {index > 0 && (
                          <div className="flex items-end">
                            <button type="button" onClick={() => removePrescription(arrayHelpers, index)} className="text-red-500 font-bold px-4 mb-1">Delete Prescription</button>
                          </div>
                        )}
                      <div key={index} className="w-full flex lg:flex-row flex-col mb-4 gap-2">
                        <div className='flex flex-col w-full'>
                          <Field name={`prescriptions.${index}.medicineName`} placeholder="Medicine Name" className="p-2 border border-gray-300 rounded w-full mb-2" type='text' />
                          <ErrorMessage name={`prescriptions.${index}.medicineName`} component="div" className="text-red-500 text-[0.60rem]" />
                        </div>

                        <div className='flex flex-col w-full'>
                          <Field name={`prescriptions.${index}.strength`} placeholder="Strength" className="p-2 border border-gray-300 rounded w-full mb-2" type='text' />
                          <ErrorMessage name={`prescriptions.${index}.strength`} component="div" className="text-red-500 text-[0.60rem]" />
                        </div>

                        <div className='flex flex-col w-full'>
                          <Field name={`prescriptions.${index}.dosage`} placeholder="Dosage" className="p-2 border border-gray-300 rounded w-full mb-2" type='text' />
                          <ErrorMessage name={`prescriptions.${index}.dosage`} component="div" className="text-red-500 text-[0.60rem]" />
                        </div>

                        <div className='flex flex-col w-full'>
                          <Field name={`prescriptions.${index}.treatmentDays`} placeholder="Treatment Days" className="p-2 border border-gray-300 rounded w-full mb-2" type='text' />
                          <ErrorMessage name={`prescriptions.${index}.treatmentDays`} component="div" className="text-red-500 text-[0.60rem]" />
                        </div>
                      </div>
                      </>
                    ))}
                    <button type="button" onClick={() => addPrescription(arrayHelpers)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4 ml-2">Add more Prescription</button>
                  </div>
                )}
              />

              <div className="my-4">
                <Field as="textarea" name="comment" className="p-2 border border-gray-300 rounded mb-2 w-full" placeholder="Comment" type='text' />
                <ErrorMessage name="comment" component="div" className="text-red-500 text-xs" />
              </div>

              {formError && <p className="text-red-500 text-xs mb-4">{formError}</p>}
              {successMessage && <p className="text-green-500 text-xs mb-4">{successMessage}</p>}

              <button type="submit" className={`bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-4 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`} disabled={loading}>
                {loading ? 'Submitting...' : 'Submit'}
              </button>
            </Form>
          )}
        </Formik>
      </div>
      <div className="w-full">
        <h2 className="text-lg font-semibold mb-4">View Prescriptions</h2>
        <ul className='sm:max-h-[30rem] overflow-y-auto'>
          {prescriptions.map((prescription, index) => (
            <li key={index} className="mb-2 p-2 border border-gray-300 rounded">
              {prescription && (
                <>
                  {prescription.medicineName && (
                    <p><strong>Medicine Name:</strong> {prescription.medicineName}</p>
                  )}
                  {prescription.strength && (
                    <p><strong>Strength:</strong> {prescription.strength}</p>
                  )}
                  {prescription.dosage && (
                    <p><strong>Dosage:</strong> {prescription.dosage}</p>
                  )}
                  {prescription.treatmentDays && (
                    <p><strong>Treatment Days:</strong> {prescription.treatmentDays}</p>
                  )}
                </>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};


  const [activeTab, setActiveTab] = useState('details'); // State to manage the active section
  const renderComponent = () => {
    switch (activeTab) {
      case 'details':
        return <AppointmentDetails/>;
      case 'prescriptions':
        return <Prescriptions/>;
      case 'follow ups':
        return <div>follow ups</div>;
      default:
        return <AppointmentDetails/>;
    }
  };

  const ActionModal = ({ onClose, onApprove, feedback}) => {

    return (
      <div className={`fixed inset-0 bg-gray-600 bg-opacity-30 overflow-y-auto h-full w-full flex justify-center px-4
        items-center lg:pl-72 ${!isActionModalVisible && 'hidden'}`}>

        <div className="relative top-20 lg:mx-auto w-full lg:w-[60rem] p-5 border shadow-lg rounded-md  bg-white">
          <div className=" w-full text-start">
            <button onClick={onClose} className="text-red-600 font-Poppins font-extrabold w-3xl bg-red-200 py-2 px-3 rounded-full">X
            </button>
          </div>

        <div className='w-full mt-4 mb-6 lg:px-10 px-3 flex justify-center items-center sm:overflow-x-hidden overflow-x-scroll' role='group'>
          <div className="inline-flex rounded-md shadow-sm w-full" role="group">
            {/* Update setActiveTab on button click and apply active styling */}
            <button
              type="button"
              className={`px-4 py-2 text-xs font-medium ${activeTab === 'details' ? 'text-blue-700 bg-gray-100' :
              'text-gray-900 bg-white'} border border-gray-200 rounded-s-lg focus:z-10 focus:ring-2
              focus:ring-blue-700`}
              onClick={() => setActiveTab('details')}
            >
              Appointment Details
            </button>
            <button
              type="button"
              className={`px-4 py-2 text-xs font-medium ${activeTab === 'prescriptions' ? 'text-blue-700 bg-gray-100' :
              'text-gray-900 bg-white'} border-t border-b border-gray-200 focus:z-10 focus:ring-2 focus:ring-blue-700`}
              onClick={() => setActiveTab('prescriptions')}
            >
              Prescriptions
            </button>
            <button
              type="button"
              className={`px-4 py-2 text-xs font-medium ${activeTab === 'follow ups' ? 'text-blue-700 bg-gray-100'
              : 'text-gray-900 bg-white'} border border-gray-200 rounded-e-lg focus:z-10 focus:ring-2
              focus:ring-blue-700`}
              onClick={() => setActiveTab('follow ups')}
            >
              Follow Ups
            </button>
          </div>
        </div>

          {renderComponent()}

        </div>
      </div>
    );
  };


  const startMeeting = async (appointmentId) => {
    try {
      const response = await api.patch(`/doctors/appointments/${appointmentId}/start`);
      if (response.status === 200) {
        console.log("Meeting started successfully");
        // Optionally update the state or show a success message
      }
    } catch (error) {
      console.error("Error starting meeting:", error);
      // Optionally show an error message
    }
  };


  useEffect(() => {
    const fetchAppointments = async (page) => {
      try {
        const response = await api.get(`/doctors/appointments?page=${page}&limit=20`);
        setAppointments(response.data.data);
        // Assuming the API response includes total pages info, adjust if necessary
        setTotalPages(response.data.totalPages || Math.ceil(response.data.total / 20));
      } catch (error) {
        console.error("Error fetching appointments:", error);
      }
    };
    // refresh time to fetch data from backend
    fetchAppointments(currentPage);
    const intervalId = setInterval(fetchAppointments, 5000, currentPage);
    return () => clearInterval(intervalId);
  }, [currentPage]);

  const handlePageClick = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  // Dynamically generate page numbers based on totalPages
  const getPageNumbers = () => {
    const pageNumbers = [];
    let startPage = Math.max(1, currentPage - Math.floor(pageLimit / 2));
    let endPage = Math.min(totalPages, currentPage + Math.floor(pageLimit / 2));

    if (startPage <= 0) {
      startPage = 1;
    }
    if (endPage > totalPages) {
      endPage = totalPages;
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };

  const statusColorMap = {
    approved: 'text-green-600',
    pending: 'text-blue-500',
    postponed: 'text-orange-500',
    cancelled: 'text-red-500'
  };


  return (
    <div className='w-full flex flex-col'>
      <div className="overflow-x-auto lg:px-0 px-0">
        {appointments.length > 0 ? (
      <div className="relative shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left max-w-appointment-max-wit">
          <thead className="text-xs text-center py-3 px-6 text-white uppercase bg-blue-700">
            <tr>
              <th scope="col" className="py-4 px-6">ID</th>
              <th scope="col" className="py-4 px-6">Username</th>
              <th scope="col" className="py-4 px-6">Patient Name</th>
              <th scope="col" className="py-4 px-6">Symptoms</th>
              <th scope="col" className="py-4 px-6">Mobile Number</th>
              <th scope="col" className="py-4 px-6">Payment Method</th>
              <th scope="col" className="py-4 px-6">Appointment Date</th>
              <th scope="col" className="py-4 px-6">Time</th>
              <th scope="col" className="py-4 px-6">Type</th>
              <th scope="col" className="py-4 px-6">Status</th>
              <th scope="col" className="py-4 px-6">Actions</th>
              <th scope="col" className="py-4 px-6">Meeting</th>
            </tr>
          </thead>
          <tbody>
            {appointments.map((appointment, index) => (
              <tr key={appointment.appointmentId} className={index % 2 === 0 ? 'bg-blue-50 text-xs' : 'bg-white text-xs'}>
                <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap">{appointment.appointmentId}</th>
                <td className="py-4 px-3">{appointment.username}</td>
                <td className="py-4 px-3">{appointment.patientNameOnPrescription}</td>
                <td className="py-4 px-3">{appointment.patientSymptoms}</td>
                <td className="py-4 px-3">{appointment.patientMobileNumber}</td>
                <td className="py-4 px-3">{appointment.paymentMethod}</td>
                <td className="py-4 px-3">{new Date(appointment.appointmentDate).toLocaleDateString()}</td>
                <td className="py-4 px-3">{appointment.appointmentTime}</td>
                <td className="py-4 px-3">{appointment.appointmentType}</td>
                <td className="py-4 px-3">
                  {appointment.appointmentStatus === 'postponed' ? (
                    <span className={statusColorMap[appointment.appointmentStatus]}>
                      {appointment.appointmentStatus}
                    </span>
                  ) : (
                    <span className={statusColorMap[appointment.appointmentStatus] || 'text-gray-500'}>
                      {appointment.appointmentStatus }
                    </span>
                  )}
              </td>
                <td className="py-4 px-6">
                  <button className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                  onClick={() => handleAction(appointment.appointmentId)}>Actions</button>
                  {/* Additional actions here */}
                  {isActionModalVisible && (
                      <ActionModal
                        onClose={() => setIsActionModalVisible(false)}
                        onApprove={handleApprove}
                        onPostpone={() => handlePostponeSubmit()}
                        feedback={actionFeedback}
                      />
                    )}
                </td>

                <td className="py-4 px-6">
                  <div className='flex'>
                  {appointment.appointmentStatus === 'approved' && (
                    <Tooltip content="Start meeting" placement="top" trigger="hover" animation="duration-150">
                      <a
                        href={appointment.meetingStartUrl}
                        target="_blank"
                        className='ml-2 cursor-pointer'
                        rel="noopener noreferrer"
                        onClick={() => startMeeting(appointment.appointmentId)} // Trigger PATCH request on click
                      >
                        <svg className="flex-shrink-0 w-5 h-5 text-green-500 transition duration-75" aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                          <path fillRule="evenodd" d="M14 7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v10c0 1.1.9 2 2 2h8a2 2 0 0 0 2-2V7Zm2 9.4 4.7 1.5A1 1 0 0 0 22 17V7a1 1 0 0 0-1.3-1L16 7.7v8.8Z" clipRule="evenodd"/>
                        </svg>
                      </a>
                    </Tooltip>
                  )}
                  </div>
                </td>


              </tr>
            ))}
          </tbody>
        </table>
      </div>) : (
            // Displayed when no appointments are present
            <div className="text-center py-8">
              <p className='text-gray-500 font-Poppins'>No appointments found.</p>
            </div>
          )}

        <div>
          {/* The existing UI elements */}
          <PostponeForm
            isOpen={isPostponeFormVisible}
            onClose={() => setIsPostponeFormVisible(false)}
            onSubmit={handlePostponeSubmit}
            initialDate={new Date().toISOString().slice(0, 10)}
            feedback={actionFeedback}
          />
        </div>
      </div>


        {/* Pagination */}
        {totalPages > 1 && (
        <nav aria-label="Page navigation example" className='flex justify-center mt-10'>
          <ul className="inline-flex -space-x-px text-sm">
            <li>
              <button
                className="px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700"
                onClick={() => handlePageClick(currentPage - 1)}
                disabled={currentPage === 1} // Disable Previous button if on first page
              >
                Previous
              </button>
            </li>

            {/* Render dynamic range of page numbers */}
            {getPageNumbers().map((number) => (
              <button
                key={number}
                onClick={() => handlePageClick(number)}
                className={`px-3 py-1 ${currentPage === number ? 'h-8 leading-tight text-gray-700 text-xs bg-blue-300 hover:bg-gray-100 hover:text-gray-700' : 'bg-white hover:bg-gray-100 text-gray-500'}`}
              >
                {number}
              </button>
            ))}

            <li>
              <button
                className="px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700"
                onClick={() => handlePageClick(currentPage + 1)}
                disabled={currentPage === totalPages} // Disable Next button if on last page
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
      )}
    </div>
  );
};

export default Appointments;
