import React, { useEffect, useState } from 'react';
import { api } from '../utils/Api'; // Assuming your API utility is correctly set up
import logo from '../images/logo2.png';
import TextFolding from '../utils/TextFolding';
import { Link, useParams } from 'react-router-dom';
import ConsultQA from './ConsultQA';
import Story from './Story';
import Healthfeed from './Healthfeed';
import StarRating from '../utils/StarRating';
import LoginIfNot from './auth/LoginIfNot';
import Lottie from 'lottie-react';
import loading_lottie from '../lottiefiles/loading_lottie.json';
import profile_icon from '../images/profile_icon.png';



const AppointmentForm = ({ specializationId, onClose}) => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [doctor, setDoctor] = useState('');

  const [formData, setFormData] = useState({
    patientName: '',
    patientNumber: '',
    symptoms: '',
    appointmentType: '',
    appointmentDate: '',
    appointmentTime: '',
  });

  const selectedTime = [
    { timeId: 1, appointmentTime: '07:00' },
    { timeId: 2, appointmentTime: '07:30' },
    { timeId: 3, appointmentTime: '08:00' },
    { timeId: 4, appointmentTime: '08:30' },
    { timeId: 5, appointmentTime: '09:00' },
    { timeId: 6, appointmentTime: '09:30' },
    { timeId: 7, appointmentTime: '10:00' },
    { timeId: 8, appointmentTime: '10:30' },
    { timeId: 9, appointmentTime: '11:00' },
    { timeId: 10, appointmentTime: '11:30' },
    { timeId: 11, appointmentTime: '12:00' },
    { timeId: 12, appointmentTime: '12:30' },
    { timeId: 13, appointmentTime: '13:00' },
    { timeId: 14, appointmentTime: '13:30' },
    { timeId: 15, appointmentTime: '14:00' },
    { timeId: 16, appointmentTime: '14:30' },
    { timeId: 17, appointmentTime: '15:00' },
    { timeId: 18, appointmentTime: '15:30' },
    { timeId: 19, appointmentTime: '16:00' },
    { timeId: 20, appointmentTime: '16:30' },
    { timeId: 21, appointmentTime: '17:00' },
    { timeId: 22, appointmentTime: '17:30' },
    { timeId: 23, appointmentTime: '18:00' },
  ]


  useEffect(() => {
    fetchDoctor();
  }, []);

  const fetchDoctor = async () => {
    try {
      const response = await api.get(`/doctor/${id}`);
      if (response.data.status === 'error') {
        throw new Error(response.data.errors[0].msg);
      }
      setDoctor(response.data.data); // Set the doctor details from response.data.data
    } catch (error) {
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    let messageTimeout;
    if (successMessage) {
      messageTimeout = setTimeout(() => {
        setSuccessMessage('');
      }, 4000);
    }

    if (errorMessage) {
      messageTimeout = setTimeout(() => {
        setErrorMessage('');
      }, 4000);
    }

    return () => clearTimeout(messageTimeout);
  }, [successMessage, errorMessage]);

  const handleSubmit = async (e, actions) => {
    e.preventDefault();
    setIsLoading(true);
      const values = { ...formData };
      values.doctorId = id;
      values.specialtyId = doctor.specialtyId;
      console.log('values', values);
      try {
        const response = await api.post('/patients/appointments', values).catch((error) => {
          throw error;
        });
        const payment = response.data.data.paymentUrl;
        if (payment) {
          setSuccessMessage('Appointment booked successfully. Redirecting to payment...');
          setErrorMessage('');
          setTimeout(() => {
            window.location.href = payment;
            onClose();
          }, 3000);
          actions.resetForm();
          values('');
        } else {
          setSuccessMessage('Appointment booked successfully.');
          setErrorMessage('');
        }
      } catch (error) {
        console.log('error',error);
        let errorMessage = ""
        const errors = error.response.data.errors;
        if(errors){
          errors.forEach(error => {
            errorMessage += error.msg + "\n";
          });
        }
        setErrorMessage(errorMessage);
        setSuccessMessage('');
      }
      setIsLoading(false);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div className="bg-white flex flex-col items-center justify-center p-3 w-popup-width sm-w-full rounded-md shadow-md">
      <div className="w-full">
          <button className="text-red-500 mb-3" onClick={onClose}>
              Close
            </button>
          </div>

        <h2 className="sm:text-lg text-gray-700 font-semibold text-center mb-4 text-sm font-Poppins">Book an appointment with {doctor.title} {doctor.firstName} {doctor.lastName}</h2>
            {successMessage && (
              <div className="bg-green-200 col-span-2 p-2 text-green-700 mb-4 rounded">
                {successMessage}
              </div>
            )}

            {errorMessage && (
              <div className="bg-red-200 col-span-2 p-2 text-red-700 mb-4 rounded">
                {errorMessage}
              </div>
            )}
        <form className='flex flex-col w-full gap-5' onSubmit={handleSubmit}>
          <input
            type="text"
            name="patientName"
            placeholder="Patient Name"
            value={formData.patientName}
            onChange={handleChange}
            className="appearance-none border border-blue-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
          <input
            type="text"
            name="patientNumber"
            placeholder="Patient Number"
            value={formData.patientNumber}
            onChange={handleChange}
            className="appearance-none border border-blue-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
          <input
            type="text"
            name="symptoms"
            placeholder="Symptoms"
            value={formData.symptoms}
            onChange={handleChange}
            className="appearance-none border border-blue-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
          <select
            name="appointmentType"
            value={formData.appointmentType}
            onChange={handleChange}
            className="appearance-none border border-blue-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          >
            <option value="">Select Appointment Type</option>
            <option value="online_consultation">online_consultation</option>
            <option value="patient_visit">patient_visit</option>
            <option value="doctor_visit">doctor_visit</option>
            {/* Add more options as needed */}
          </select>

          <input
            type="date"
            name="appointmentDate"
            value={formData.appointmentDate}
            onChange={handleChange}
            className="appearance-none border border-blue-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />

          <select
            name="appointmentTime"
            value={formData.appointmentTime}
            onChange={handleChange}
            className="appearance-none border border-blue-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          >
            <option value="">Select Appointment Time</option>
            {selectedTime.map(time => (
              <option key={time.timeId} value={time.appointmentTime}>
                {time.appointmentTime}
              </option>
            ))}
          </select>

            <input type="hidden" name="doctorId" value={id} />
            <input type="hidden" name="specializationId" value={specializationId} />
          {/* Add more input fields for appointment time slot and any other fields */}
          <button type="submit"
          disabled={isLoading}
            class="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white
            rounded-lg border border-blue-300  hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4
            focus:ring-gray-200 w-full mt-5 font-Poppins"
          >
            {isLoading ? 'Loading...' : 'Submit Appointment'}
          </button>
        </form>
      </div>
    </div>
  );
};






const QRL_DoctorDetails = () => {
  const { id } = useParams();
  const [doctor, setDoctor] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('story');
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false); // State to manage login modal visibility
  const [showAppointmentForm, setShowAppointmentForm] = useState(false);

  // Function to render the component based on activeTab state
  const renderComponent = () => {
    switch (activeTab) {
      case 'story':
        return <Story />;
      case 'consultQA':
        return <ConsultQA />;
      case 'healthFeed':
        return <Healthfeed />;
      default:
        return <Story />;
    }
  };

  useEffect(() => {
    fetchDoctor();
  }, []);

  const fetchDoctor = async () => {
    try {
      const response = await api.get(`/doctor/${id}`);
      if (response.data.status === 'error') {
        throw new Error(response.data.errors[0].msg);
      }
      setDoctor(response.data.data); // Set the doctor details from response.data.data
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const shareDoctorProfile = () => {
    if (navigator.share) {
      navigator.share({
        title: `${doctor.title} ${doctor.firstName} ${doctor.middleName} ${doctor.lastName}`,
        text: 'Check out this doctor on Kenecare!',
        url: window.location.href,
      }).then(() => {
        console.log('Successful share');
      }).catch((error) => {
        console.error('Error sharing', error);
      });
    } else {
      alert('Sharing is not supported on this browser.');
    }
  };

  const handleBookAppointment = () => {
    const token = localStorage.getItem("token");
    const userType = localStorage.getItem("type");

    if (token && userType === '1') {
      setShowAppointmentForm(true);
    } else if (token && userType === '2') {
      alert('Doctor cannot book appointment');
    }
     else {
      setIsLoginModalOpen(true);
    }
  };

  return (
    <div className="bg-gray-100">
      {loading ? (
        <div className="w-full h-screen flex justify-center items-center">
          <div className='w-[10rem] h-[10rem]'>
            <Lottie animationData={loading_lottie} width={300} height={300} loop={true} />
          </div>

        </div>
      ) : error ? (
        <div className="w-full h-screen flex justify-center items-center">
          <p className="text-center text-red-600">{error}</p>
        </div>
      ) : (
        <div className="flex flex-col w-full items-center">
          <div className="w-full flex justify-center items-center bg-white border-b shadow">
            <Link
              to="https://app.kenecare.com/"
              className="w-full max-w-7xl flex flex-row items-end py-1 px-3"
            >
              <img src={logo} alt="QRL Logo" className="h-[4rem]" />
              <h1 className="text-2xl ml-2 font-bold text-blue-600">Kenecare</h1>
            </Link>
          </div>



          <div className='w-full max-w-7xl mt-10'>
          <Link type="button" to={'/userDashboard'}
            className="text-blue-500 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center">
            <svg className="w-6 h-6 text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
              fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 12h14M5 12l4-4m-4 4 4 4"/>
            </svg>
            Goto to Dashboard
          </Link>
        </div>

          <div className="flex sm:flex-row flex-col max-w-7xl w-full justify-between mt-8 px-3">
            <div className="sm:max-w-[60%] w-full bg-white px-2 py-4">
              <div className="w-full flex flex-row justify-start">
                <img
                  src={doctor.profilePic || profile_icon}
                  alt="Doctor Profile"
                  className="rounded-full w-[9rem] h-[9rem] mb-4 shadow "
                />
                <div className="ml-4 text-sm font-Poppins">
                  <h2 className="text-lg font-bold mb-1 mt-2 font-Poppins">
                    {doctor.title} {doctor.firstName} {doctor.middleName} {doctor.lastName}
                  </h2>
                  <p className="text-gray-700">Specialty: {doctor.specialization}</p>
                  <p className="text-gray-600">Location: {doctor.city}</p>
                  <p className="text-gray-600">Gender: {doctor.gender}</p>
                  <p className="text-gray-600">Mobile: {doctor.mobileNumber}</p>
                  <p className="text-gray-600">Email: {doctor.email}</p>
                </div>
              </div>

              <div className="w-full">
                <p className="text-gray-600 mt-4">Qualifications: {doctor.qualifications}</p>
                <p className="text-gray-600">Consultation Fees: SLL{doctor.consultationFees}</p>
                <p className="text-gray-600 mb-3">Years of Experience: {doctor.yearOfExperience}</p>
                <TextFolding initialText={doctor.professionalSummary} foldThreshold={200} />
              </div>
            </div>

            <div className="w-full mt-4 sm:mt-0 sm:max-w-[33.333%] flex flex-col">
              <div className="w-full  bg-white rounded h-fit">
                <div className="bg-blue-200 py-3 rounded-t">
                  <p className="text-gray-800 font-Poppins text-lg ml-4">
                    Consultation Fee: SLL{doctor.consultationFees}
                  </p>
                </div>

                <div className="px-3 border-b mb-3">
                  <h2 className="text-lg font-bold text-blue-600 mt-2">Working Hours</h2>
                </div>
                <div className="flex flex-row justify-between px-3">
                  <p className="text-gray-600">Monday</p>
                  <p className="text-gray-600">8:00 am - 5:00 pm</p>
                </div>
                <div className="flex flex-row justify-between px-3">
                  <p className="text-gray-600">Tuesday</p>
                  <p className="text-gray-600">8:00 am - 5:00 pm</p>
                </div>
                <div className="flex flex-row justify-between px-3">
                  <p className="text-gray-600">Wednesday</p>
                  <p className="text-gray-600">8:00 am - 5:00 pm</p>
                </div>
                <div className="flex flex-row justify-between px-3">
                  <p className="text-gray-600">Thursday</p>
                  <p className="text-gray-600">8:00 am - 5:00 pm</p>
                </div>
                <div className="flex flex-row justify-between px-3">
                  <p className="text-gray-600">Friday</p>
                  <p className="text-gray-600">8:00 am - 5:00 pm</p>
                </div>
                <button
                  className="bg-blue-600 hover:bg-blue-500 text-white w-full py-2 rounded-b mt-5"
                  onClick={handleBookAppointment}
                >
                  Book Appointment
                </button>
              </div>

              <div className="w-full bg-white rounded mt-4 p-4 flex justify-between items-center">
                <p className="text-gray-800">Share Doctor Profile</p>
                <button
                aria-label="share"
                  className=" py-2 px-4 rounded"
                  onClick={shareDoctorProfile}
                >
                  <svg class="w-6 h-6 text-blue-500 hover:text-blue-400"
                    aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                    <path fill-rule="evenodd" d="M14.516 6.743c-.41-.368-.443-1-.077-1.41a.99.99 0 0 1 1.405-.078l5.487 4.948.007.006A2.047 2.047 0 0 1 22 11.721a2.06 2.06 0 0 1-.662 1.51l-5.584 5.09a.99.99 0 0 1-1.404-.07 1.003 1.003 0 0 1 .068-1.412l5.578-5.082a.05.05 0 0 0 .015-.036.051.051 0 0 0-.015-.036l-5.48-4.942Zm-6.543 9.199v-.42a4.168 4.168 0 0 0-2.715 2.415c-.154.382-.44.695-.806.88a1.683 1.683 0 0 1-2.167-.571 1.705 1.705 0 0 1-.279-1.092V15.88c0-3.77 2.526-7.039 5.967-7.573V7.57a1.957 1.957 0 0 1 .993-1.838 1.931 1.931 0 0 1 2.153.184l5.08 4.248a.646.646 0 0 1 .012.011l.011.01a2.098 2.098 0 0 1 .703 1.57 2.108 2.108 0 0 1-.726 1.59l-5.08 4.25a1.933 1.933 0 0 1-2.929-.614 1.957 1.957 0 0 1-.217-1.04Z" clip-rule="evenodd"/>
                  </svg>

                </button>
              </div>

              <div className="w-full bg-white rounded mt-4 p-4 flex items-center">
                <img src={doctor.profilePic || profile_icon} alt="Doctor Profile" className="w-16 h-16 rounded-full" />
                <div className="flex flex-col ml-5">
                  <p className='text-gray-600'>Doctor ratings</p>
                  <StarRating rating={doctor.rating} />
                </div>
              </div>

            </div>
          </div>

          <div className="w-full max-w-7xl px-3">
            <div className="sm:w-3/5 w-full flex items-start sm:overflow-x-hidden overflow-x-scroll mt-10">
              <div className="inline-flex rounded-md shadow-sm" role="group">
                {/* Update setActiveTab on button click and apply active styling */}
                <button
                  type="button"
                  className={`px-4 py-2 text-xs font-bold font-Poppins ${
                    activeTab === 'story' ? 'text-blue-700 bg-white' : 'text-gray-600 bg-gray-100'
                  } focus:z-10`}
                  onClick={() => setActiveTab('story')}
                >
                  Stories
                </button>
                <button
                  type="button"
                  className={`px-4 py-2 text-xs font-bold font-Poppins ${
                    activeTab === 'consultQA' ? 'text-blue-700 bg-white' : 'text-gray-600 bg-gray-100'
                  } focus:z-10`}
                  onClick={() => setActiveTab('consultQA')}
                >
                  Consult Q&A
                </button>
                <button
                  type="button"
                  className={`px-4 py-2 text-xs font-bold font-Poppins ${
                    activeTab === 'healthFeed' ? 'text-blue-700 bg-white' : 'text-gray-600 bg-gray-100'
                  } focus:z-10`}
                  onClick={() => setActiveTab('healthFeed')}
                >
                  Healthfeed
                </button>
              </div>
            </div>

            <div className="sm:w-3/5 w-full bg-white mb-10">{renderComponent()}</div>
          </div>

          <div className="w-full py-7 bg-blue-800 flex flex-col justify-center items-center">
            <div className="w-full flex justify-center items-center">
              <div className="lg:w-3/5 w-full flex sm:flex-row flex-col justify-between items-center p-3">
                <Link
                  to="https://app.kenecare.com/"
                  className="rounded-full mt bg-white sm:h-[10rem] sm:w-[10rem] h-[6rem] w-[6rem] flex justify-center items-center"
                >
                  <img src={logo} className="sm:h-[6rem] h-[4rem]" alt="logo" />
                </Link>

                <div className="sm:w-1/3 w-full px-3">
                  <h2 className="text-white font-bold text-lg">Kenecare</h2>
                  <p className="text-white">
                    Kenecare is a platform that connects patients with healthcare providers. Our goal
                    is to provide quality healthcare services to patients across Sierra Leone.
                  </p>
                </div>

                <div className="sm:w-1/3 px-3">
                  <h2 className="text-white font-bold text-lg">Contact Us</h2>
                  <p className="text-white">+232 76 123 456</p>
                  <p className="text-white">88 Pademba Road, Freetown</p>
                </div>
              </div>
            </div>
            <div className="max-w-7xl w-full bg-blue-300 h-0.5 my-5"></div>
            <div className="w-full flex justify-center items-center">
              <p className="text-white">© 2024 Kenecare. All rights reserved.</p>
            </div>
          </div>
        </div>
      )}
       {/* Conditional rendering of LoginIfNot modal */}
       {isLoginModalOpen && <LoginIfNot onClose={() => setIsLoginModalOpen(false)} />}

      {showAppointmentForm && <AppointmentForm specializationId={doctor.specializationId} onClose={() => setShowAppointmentForm(false)} />}
    </div>
  );
};

export default QRL_DoctorDetails;
