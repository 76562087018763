import React from 'react'
import QRL_DoctorDetails from '../../components/QRL_DoctorDetails'

const QRL_details = () => {
  return (
    <>
    <QRL_DoctorDetails/>

    </>
  )
}

export default QRL_details
