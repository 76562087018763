import React from 'react';
import { Chart, LineController, LinearScale, CategoryScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';

Chart.register(LineController, LinearScale, CategoryScale, PointElement, LineElement, Title, Tooltip, Legend);

const chartData = {
  labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
  datasets: [
    {
      label: 'appointments',
      data: [50, 12, 28, 29, 7, 25, 12, 50, 58, 40, 30, 26],
      borderColor: 'rgb(53, 162, 235)',
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
      yAxisID: 'y1',
    },
  ],
};

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    mode: 'index',
    intersect: false,
  },
  stacked: false,
  plugins: {
    title: {
      display: true,
      text: 'Appointments Chart',
      color: 'white',
    },
  },
  legend: {
    labels: {
      color: 'white',
    },
  },
  scales: {
    x: {
      display: true,
      title: {
        display: true,
        text: 'Months',
        color: 'white',
      },
      ticks: {
        color: 'white',
      },
    },
    y1: {
      display: true,
      position: 'left',
      title: {
        display: true,
        text: 'appointments',
        color: 'white',
      },
      ticks: {
        color: 'white',
      },
    },
  },
};

const AppointmentsChart = () => {
  return<div className='w-full sm:h-[30rem] h-full sm:px-4
  '> <Line data={chartData} options={chartOptions} /> </div>
};

export default AppointmentsChart;
