import React, { useState, useEffect } from 'react';
import { api } from '../utils/Api';
import { Button } from '@chatscope/chat-ui-kit-react';

const Articles = () => {
  const [articles, setArticles] = useState([]);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true); // Set loading to true at the start of the API call
      try {
        const response = await api.get('/blogs');
        if (Array.isArray(response.data.data)) {
          setArticles(response.data.data);
          setIsLoading(false); // Ensure to set loading to false if an error occurs
        } else {
          setIsLoading(false); // Ensure to set loading to false if an error occurs
        }
      } catch (error) {
        setIsLoading(false); // Ensure to set loading to false if an error occurs
      }
    };

    fetchData();
  }, []);

  const openPopup = (article) => {
    setSelectedArticle(article);
  };

  const closePopup = () => {
    setSelectedArticle(null);
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center py-8">
        <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200
            h-32 w-32 flex justify-center items-center">
            <p className="text-gray-500 ml-4 text-sm">Loading...</p>
          </div>
      </div>
    );
  }

  return (
    <div>
      <section className="flex flex-col justify-center max-w-7xl">
      <label htmlFor="specialty" className="text-lg font-medium text-gray-700
      font-Poppins mb-3">Important Health Articles</label>
      <p className='mb-4 text-sm text-gray-700'>
        Stay informed and prioritize your health by exploring essential health topics. <br /> Educate yourself to make better
        choices for a healthier lifestyle.
      </p>
        <div className="w-full flex sm:flex-row flex-col items-center justify-center">
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
            {articles.slice(0, 6).map((article, index) => (
              <div key={index} className="bg-white rounded-md shadow-md h-96 pb-2 overflow-hidden">
                <img src={article.image} alt={article.title} className="w-full h-48 object-cover" />
                <div className="p-4">
                  <Button className="text-sm font-semibold" onClick={() => openPopup(article)}>{article.blogTitle}</Button>
                  <p className="text-sm text-gray-600">{article.blogCategory}</p>
                  <p className="text-xs text-gray-600">{article.description.slice(0, 300)}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      {selectedArticle && (
      <div className="fixed inset-0 flex items-center justify-center lg:pl-72 bg-gray-800 bg-opacity-50 overflow-y-auto ">
        <div className="bg-white py-6 px-3 max-w-lg rounded-md shadow-md sm:mt-0 mt-20">
          <span className="text-red-500 bg-red-200 px-2 py-1 rounded-full font-bold text-2xl hover:cursor-pointer mb-6" onClick={closePopup}>&times;</span>
          <span className="absolute top-0 right-0 cursor-pointer p-2 " onClick={closePopup}>&times;</span>
          <img src={selectedArticle.image} alt={selectedArticle.blogTitle} className="w-full h-48 object-cover mt-3" />
          <h2 className="text-lg font-semibold mb-3">{selectedArticle.blogTitle}</h2>
          <h2 className="text-lg font-semibold mb-1">{selectedArticle.blogCategory}</h2>
          <p className='text-sm text-gray-700 mb-3'>{selectedArticle.description.slice(0, 150)}
            <a href={`https://kenecare.com/blog-detail?id=${selectedArticle.blogId}`}
            target="_blank" rel="noopener noreferrer"
            className="text-blue-500 hover:underline mt-3">...Read more on Kenecare's website</a></p>
          <div className="mt-4">
            <p className="text-sm font-semibold mb-2">Tags:</p>
            <ul className="flex flex-wrap">
              {selectedArticle.tags.split(',').map((tag, index) => (
                <li key={index} className="mr-2 mb-2 bg-gray-200 rounded-full px-2 py-1 text-sm">{tag.trim()}</li>
              ))}
            </ul>
          </div>
          <div className='flex flex-col'>
          <a href={`https://kenecare.com/health-feed`} target="_blank" rel="noopener noreferrer"
            className="text-blue-600 hover:underline mt-1">See all Articles on Kenecare website</a>
          </div>

        </div>
      </div>
      )}
    </div>
  );
};

export default Articles;
